.ant-layout-header {
    background-color: white !important;
}

.logo {
    float: left;
    height: 30px;
}

.logo > img {
    height: 30px;
}
.site-layout .site-layout-background {
    /*background: #fff;*/
}

.factoryId {
    pointer-events: none;
    color: rgba(0, 0, 0, 0.5) !important;
    text-decoration: underline;
}
